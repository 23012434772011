<template>
  <div id="div-home">
    <div class="row feed boxw mb-5">
      <div class="box pb-3 ">
        <h3 class="titulo">Criar POST</h3>
        <div class="pd-t-15 pd-l-15 pd-r-15 pd-b-0"><p class="m-0">
          <textarea id="post-edit124" v-model="post.description" class="form-control h-150"></textarea>
        </p></div>
        <div class="pd-15">
          <span class="button button6 fileinput-button text-center">
            <i class="fas fa-download m-r-5"></i>
            <span>imagem</span>
            <input type="file" name="file" ref="file" v-on:change="fileChange()">
          </span>
        </div>
        <div class="m-15 m-t-0 text-center m-b-0">
          <a class="button button2" @click="createPostFN">Guardar</a>
          <router-link :to="'/profile/' + user.id + '/' + user.username" class="button button12 m-t-15">Cancelar</router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { postsCreate } from './../resources/fn'
import { error } from '../resources/functions'

export default {
  name: 'post',
  data () {
    return {
      post: {
        description: '',
        image: ''
      },
      previewImage: ''
    }
  },
  beforeMount () {
    this.SET_LOAD(false)
    window.setTimeout(() => {
      document.getElementById('post-edit124').focus()
    }, 300)
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  methods: {
    ...mapMutations([
      'SET_LOAD'
    ])
  },
  createPostFN () {
    if (this.post.image) {
      this.SET_LOAD(true)
      var data = new window.FormData()
      data.append('image', this.post.image)
      data.append('description', this.post.description)
      postsCreate(this.axios, data).then((res) => {
        this.$router.push('/post/' + res.user_id + '/' + res.username + '/' + res.id)
      }, () => {
        error(this, 'nao foi possivel criar post')
        this.SET_LOAD()
      })
    } else {
      error(this, 'tem que seleccionar uma imagem')
    }
  },
  fileChange () {
    var input = this.$refs.fileInput
    var file = input.files
    if (file && file[0]) {
      return
    }
    var reader = new FileReader()
    reader.onload = e => {
      this.previewImage = e.target.result
      console.log(this.previewImage)
    }
    reader.readAsDataURL(file[0])
    this.post.image = file[0]
    console.log(this.post.image)
  }
}
</script>
